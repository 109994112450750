import { APP_DESCRIPTION, TITLE, APP_NAME } from '@constants/head';
import { PAGES } from '@shared/constants';

import { Component } from 'react';
import Head from 'next/head';
import { withRouter } from 'next/router';

class OpenGraph extends Component {
  shouldComponentUpdate() {
    return false;
  }

  render() {
    const { children, title, description, url, image, type, imageWidth, imageHeight } = this.props;

    return (
      <Head>
        <meta property="og:site_name" key="og:site_name" content={APP_NAME} />
        <meta property="og:title" key="og:title" content={title} />
        <meta property="og:description" key="og:description" content={description} />
        <meta property="og:url" key="og:url" content={url} />
        <meta property="og:image" key="og:image" content={image} />
        <meta property="og:image:secure_url" key="og:image:secure_url" content={image} />
        <meta property="og:type" key="og:type" content={type} />
        {imageWidth ? (
          <meta property="og:image:width" key="og:image:width" content={imageWidth} />
        ) : null}
        {imageHeight ? (
          <meta property="og:image:height" key="og:image:height" content={imageHeight} />
        ) : null}
        <meta name="apple:title" key="apple:title" content={title} />
        <meta name="apple:description" key="apple:description" content={description} />

        {children}
      </Head>
    );
  }
}

OpenGraph.defaultProps = {
  title: TITLE,
  description: APP_DESCRIPTION,
  url: PAGES.WEB_PLAYER_INDEX,
  image: 'https://radiojavan.com/images/rj-touch-icon-144.png',
  type: 'website'
};

export default withRouter(OpenGraph);

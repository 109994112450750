import { getProtocolLink, getWebPlayerLink, getAlternateLink } from '@functions/get-link';

import { useMemo } from 'react';
import Head from 'next/head';

const Links = ({ children, data, image, hasCanonicalLink }) => {
  return useMemo(() => {
    return (
      <Head>
        <meta property="al:android:url" key="al:android:url" content={getProtocolLink(data)} />

        {/*
         *  iOS app info
         */}
        {/* <meta property="al:ios:app_name" key="al:ios:app_name" content="Radio Javan" />
        <meta property="al:ios:app_store_id" key="al:ios:app_store_id" content="286225933" />
        <meta property="al:ios:url" key="al:ios:url" content={getProtocolLink(data)} /> */}
        <meta
          name="apple-itunes-app"
          key="apple-itunes-app"
          content={`app-id=286225933, app-argument=${getProtocolLink(data)}`}
        />

        <meta
          name="twitter:app:url:iphone"
          key="twitter:app:url:iphone"
          content={getProtocolLink(data)}
        />
        <meta
          name="twitter:app:url:ipad"
          key="twitter:app:url:ipad"
          content={getProtocolLink(data)}
        />
        <meta
          name="twitter:app:url:googleplay"
          key="twitter:app:url:googleplay"
          content={getProtocolLink(data)}
        />

        {/*
         *  Links
         */}
        {hasCanonicalLink ? (
          <link rel="canonical" key="canonical" href={getWebPlayerLink(data)} />
        ) : null}
        <link rel="alternate" key="alternate" href={getAlternateLink(data)} />
        {image && <link rel="image_src" key="image_src" href={image} />}

        {children}
      </Head>
    );
  }, []);
};

Links.defaultProps = {
  hasCanonicalLink: true
};

export default Links;

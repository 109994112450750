import { APP_DESCRIPTION, KEYWORDS } from '@constants/head';

import { Component } from 'react';
import Head from 'next/head';

import OpenGraph from '@components/head/seo/OpenGraph';
import Twitter from '@components/head/seo/Twitter';
import Links from '@components/head/seo/Links';

// TODO: UPDATE THESE
// <meta name="msapplication-config" content="/icons/browserconfig.xml" />

export default class HomeSeo extends Component {
  shouldComponentUpdate() {
    return false;
  }

  render() {
    return (
      <>
        <Head>
          <title key="title">{process.env.APP_NAME}</title>
          <meta name="description" key="description" content={APP_DESCRIPTION} />
          <meta name="keywords" key="keywords" content={KEYWORDS} />
        </Head>
        <OpenGraph />
        <Twitter />
        <Links />
      </>
    );
  }
}

HomeSeo.defaultProps = {};

import { APP_DESCRIPTION, TITLE } from '@constants/head';

import { Component } from 'react';
import Head from 'next/head';

export default class DefaultSeo extends Component {
  shouldComponentUpdate() {
    return false;
  }

  render() {
    const { children, title, description, image, card } = this.props;

    return (
      <Head>
        <meta property="twitter:title" key="twitter:title" content={title} />
        <meta property="twitter:description" key="twitter:description" content={description} />
        <meta property="twitter:image" key="twitter:image" content={image} />
        <meta name="twitter:image:alt" key="twitter:image:alt" content={title} />
        <meta name="twitter:image:src" key="twitter:image:src" content={image} />
        <meta property="twitter:card" key="twitter:card" content={card} />
        {children}
      </Head>
    );
  }
}

DefaultSeo.defaultProps = {
  title: TITLE,
  description: APP_DESCRIPTION,
  image: 'https://radiojavan.com/images/rj-touch-icon-144.png',
  card: 'summary'
};

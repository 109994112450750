import dynamic from 'next/dynamic';
// import { getServerSideData } from '@ssr/data';
// import { loadHomeItems } from '@api/home';

import HomeSeo from '@components/home/seo/HomeSeo';
import { isWeb } from '@functions/env';

const TopNavUpdater = dynamic(() => import('@components/desktop/TopNavUpdater'), { ssr: false });
const MainHome = dynamic(() => import('@components/home/Home'), { ssr: false });

const Home = MainHome;

Home.Seo = ({ isNowPlaying }) => {
  if (isWeb()) {
    if (isNowPlaying) {
      return null;
    }
  }

  return (
    <>
      <HomeSeo />
      <TopNavUpdater />
    </>
  );
};

// Home.getInitialProps = getServerSideData({
//   loaders: {
//     rows: loadHomeItems
//   }
// });

Home.layout = 'app';

export default Home;
